import { Carousel } from '@mantine/carousel'
import { SimpleGrid, Stack, Text } from '@mantine/core'
import { useMediaQuery, useViewportSize } from '@mantine/hooks'
import React from 'react'
import { Fade } from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next'
import { MiddleContainer } from '../../assets/middle-container'
import { maxWidthLaptopSmall, maxWidthMobile } from '../../constants'
import { CardIndusty } from './card-industy'
import CardWhoWeAre from './card-who-we-are'
import { useStyles } from './who-we-are-classes'
import './who-we-are-classes.scss'

export const WhoWeAreSection = () => {
  const isLaptopS = useMediaQuery(maxWidthLaptopSmall)
  const isMobile = useMediaQuery(maxWidthMobile)
  const { height, width } = useViewportSize()
  const { classes } = useStyles()
  const paddingLeftCard = isMobile ? '1em' : 0
  const { t } = useTranslation()

  return (
    <MiddleContainer>
      <Stack
        h={'100%'}
        spacing={height < 800 || (height < 1000 && width < 1000) ? '3em' : '7em'}
        justify={'center'}
        id={'who-we-are-section'} 
        style={{
          scrollMargin: '5rem'
        }}
      >
        <Text
          align="center"
          color="#1e6cbb"
          size={isMobile ? '1.75em' : '2em'}
          ff="Noto Sans"
          fw="bold"
        >
          {t('industries')}
        </Text>
        <Stack h={'100%'} spacing={isMobile ? '5em' : '2em'} justify={'center'}>
          <Fade damping={0.1} duration={1000} cascade delay={100}>
            {isLaptopS ? (
              <Carousel
                withIndicators
                w="100%"
                h={'100%'}
                align={'center'}
                slideSize={isMobile ? '100%' : '48%'}
                slideGap={isMobile ? '1em' : '2em'}
                slidesToScroll={isMobile ? 1 : 2}
                loop
                classNames={{
                  controls: classes.controls,
                  control: classes.control,
                  indicators: classes.indicators,
                  indicator: classes.indcator,
                }}
              >
                <Carousel.Slide pr={'2em'} pl={paddingLeftCard}>
                  <CardIndusty
                    title={t('metering_industry')}
                    icon={'/metering.png'}
                    content={t('content_metering_industry')}
                  />
                </Carousel.Slide>
                <Carousel.Slide pr={'2em'} pl={paddingLeftCard}>
                  <CardIndusty
                    title={t('web_industry')}
                    icon={'/web.png'}
                    content={t('content_web_industry')}
                  />
                </Carousel.Slide>
                <Carousel.Slide pr={'2em'} pl={paddingLeftCard}>
                  <CardIndusty
                    title={t('automotive_industry')}
                    icon={'/automotive.png'}
                    content={t('content_automotive_industry')}
                  />
                </Carousel.Slide>
                <Carousel.Slide pr={'2em'} pl={paddingLeftCard}>
                  <CardIndusty
                    title={t('iot_industry')}
                    icon={'/iot.png'}
                    content={t('content_iot_industry')}
                  />
                </Carousel.Slide>
              </Carousel>
            ) : (
              <SimpleGrid cols={4} h="100%">
                <CardIndusty
                  title={t('metering_industry')}
                  icon={'/metering.png'}
                  content={t('content_metering_industry')}
                />
                <CardIndusty
                  title={t('web_industry')}
                  icon={'/web.png'}
                  content={t('content_web_industry')}
                />
                <CardIndusty
                  title={t('automotive_industry')}
                  icon={'/automotive.png'}
                  content={t('content_automotive_industry')}
                />
                <CardIndusty
                  title={t('iot_industry')}
                  icon={'/iot.png'}
                  content={t('content_iot_industry')}
                />
              </SimpleGrid>
            )}
            <CardWhoWeAre />
          </Fade>
        </Stack>
      </Stack>
    </MiddleContainer>
  )
}
