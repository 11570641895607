import { rem, createStyles } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
  footer: {
    width: '100vw',
    maxHeight: 200,
    height: 'auto',
    paddingTop: theme.spacing.md,
    paddingBottom: theme.spacing.xs,
    backgroundColor: '#1E6CBB',
    borderTop: `${rem(1)} solid ${theme.colors.gray[2]}`,
    [theme.fn.largerThan('sm')]: {
      paddingLeft: '2em',
      paddingRight: '2em',
    },
    [theme.fn.largerThan('md')]: {
      paddingLeft: '3.5em',
      paddingRight: '3.5em',
    },
    [theme.fn.largerThan('xl')]: {
      paddingLeft: '5em',
      paddingRight: '5em',
    },
    [theme.fn.largerThan(1800)]: {
      paddingLeft: '10em',
      paddingRight: '10em',
    },
    [theme.fn.largerThan(2000)]: {
      paddingLeft: '15em',
      paddingRight: '15em',
    },
    [theme.fn.largerThan(2300)]: {
      paddingLeft: '25em',
      paddingRight: '25em',
    },
  },

  description: {
    marginTop: rem(5),
    width: '40%',
    textAlign: 'left',
    [theme.fn.smallerThan('sm')]: {
      marginTop: theme.spacing.xs,
      width: '80%',
      textAlign: 'center',
    },
  },

  inner: {
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'space-between',

    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  groups: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: '3em',

    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  wrapper: {
    maxWidth: rem(160),
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },

  link: {
    display: 'block',
    color: 'white',
    fontSize: theme.fontSizes.sm,
    paddingTop: rem(3),
    paddingBottom: rem(3),
  },

  title: {
    fontSize: theme.fontSizes.lg,
    fontWeight: 700,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    marginBottom: `calc(${theme.spacing.xs} / 2)`,
    color: 'white',
  },

  afterFooter: {
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing.xs,
    paddingTop: theme.spacing.xs,
    borderTop: '1px solid #79ceff4d',

    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
      padding: '0 0 5 0',
      marginTop: 5,
    },
  },

  social: {
    [theme.fn.smallerThan('sm')]: {
      marginTop: theme.spacing.xs,
    },
  },
}))
