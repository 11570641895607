import { Paper, SimpleGrid, Stack, Text, Image, Center } from '@mantine/core'
import { useMediaQuery, useViewportSize } from '@mantine/hooks'
import React from 'react'
import { maxWidthMobile } from '../../constants'
import { Carousel } from '@mantine/carousel'
import { dataClients } from '../../mock-data'
import { Fade } from 'react-awesome-reveal'
import { useStyles } from './our-clients-classes'
import { useTranslation } from 'react-i18next'
import { MiddleContainer } from '../../assets/middle-container'

export const OurClientsSection = () => {
  const { classes } = useStyles()
  const isMobile = useMediaQuery(maxWidthMobile)
  const { height, width } = useViewportSize()

  const { t } = useTranslation()

  const cardClient = dataClients.map((client, index) => (
    <Paper key={index} bg={'#f5f9fc'} radius={'xl'} h={'fit-content'} w="auto" p={'xl'}>
      <Center>
        <Image alt='logo-client1' src={client.logoClient} height={'4em'} width="auto" />
      </Center>
    </Paper>
  ))
  const slidesClients = dataClients.map((client, index) => (
    <Carousel.Slide key={index} w={'fit-content'}>
      <Center p={'xl'}>
        <Image alt='logo-client2' src={client.logoClient} height={'5.5em'} width="auto" />
      </Center>
    </Carousel.Slide>
  ))
  return (
    <MiddleContainer>
      <Stack
        h={'100%'}
        spacing={height < 800 || (height < 1000 && width < 1000) ? '3em' : '7em'}
        justify="center"
        align="center"
        id="our-clients-section"
        style={{
          scrollMargin: '15rem'
        }}
      >
        <Text
          align="center"
          color="#1e6cbb"
          size={'2em'}
          ff="Noto Sans"
          fw="bold"
          id="our-clients-section"
        >
          {t('our_clients')}
        </Text>
        {isMobile ? (
          <Carousel
            withIndicators
            height={'fit-content'}
            w="100%"
            align={'center'}
            slideSize={'100%'}
            slideGap={'1em'}
            slidesToScroll={1}
            loop
            classNames={{
              controls: classes.controls,
              control: classes.control,
              indicators: classes.indicators,
              indicator: classes.indcator,
            }}
          >
            {slidesClients}
          </Carousel>
        ) : (
          <SimpleGrid
            cols={5}
            w="100%"
            breakpoints={[
              { maxWidth: '80em', cols: 3 },
              { maxWidth: '62em', cols: 2 },
              { maxWidth: '39em', cols: 1 },
            ]}
          >
            <Fade damping={0.2} duration={1000} cascade delay={200}>
              {cardClient}
            </Fade>
          </SimpleGrid>
        )}
      </Stack>
    </MiddleContainer>
  )
}
