import { Burger, Button, Drawer, Flex, Group, Image, NavLink, Stack } from '@mantine/core'
import { useMediaQuery, useWindowScroll } from '@mantine/hooks'
import React, { FunctionComponent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LanguagePicker } from '../../assets/select-language'
import { maxWidthLaptopSmall, maxWidthMobile } from '../../constants'
import { useStyles } from './header-clases'
import {
  IconAddressBook,
  IconHeartHandshake,
  IconHome,
  IconInfoHexagon,
  IconUsersGroup,
} from '@tabler/icons-react'

export const Header: FunctionComponent = () => {
  const isMobile = useMediaQuery(maxWidthMobile)
  const isLaptopS = useMediaQuery(maxWidthLaptopSmall)
  const { classes } = useStyles()
  const [openedMenu, setOpenedMenu] = useState(false)
  const [scroll] = useWindowScroll()
  const { t } = useTranslation()
  const [activeLink, setActiveLink] = useState(0)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const links = [
    { link: 'introduction-section', label: t('home') },
    { link: 'how-we-can-help-section', label: t('how_we_can_help') },
    { link: 'who-we-are-section', label: t('who_we_are_title') },
    { link: 'our-clients-section', label: t('our_clients') },
    { link: 'get-in-touch-section', label: t('get_in_touch') },
  ]

  const linksMobile = [
    { icon: IconHome, link: 'introduction-section', label: t('home') },
    { icon: IconHeartHandshake, link: 'how-we-can-help-section', label: t('how_we_can_help') },
    { icon: IconInfoHexagon, link: 'who-we-are-section', label: t('who_we_are_title') },
    { icon: IconUsersGroup, link: 'our-clients-section', label: t('our_clients') },
    { icon: IconAddressBook, link: 'get-in-touch-section', label: t('get_in_touch') },
  ]

  const goToSection = (section: any) => {
    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: 'auto', block: 'start' });
    }
  };

  const itemsMobile = linksMobile.map((link, index) => (
    <NavLink
      fw={index === activeLink ? 700 : 500}
      key={link.label}
      icon={<link.icon size="1.5rem" stroke={1.5} />}
      label={link.label}
      className={index === activeLink ? classes.linkActive : classes.link}
      component="a"
      active={index === activeLink}
      onClick={() => {
        goToSection(link.link)
        setActiveLink(index)
        setOpenedMenu(false)
      }}
    />
  ))

  const items = links.map((link, index) => (
    <Button
      key={link.label}
      radius="md"
      variant="tansparent"
      className={index === activeLink ? classes.linkActive : classes.link}
      component="a"
      onClick={() => {
        goToSection(link.link)
        setActiveLink(index)
      }}
    >
      {link.label}
    </Button>
  ))

  const headerClass = scroll.y < 10 ? classes.headerStatic : classes.headerScrolled; 
  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const fromTop = window.scrollY;
      const center = fromTop + windowHeight / 2;
  
      links.forEach((link, index) => {
        const section = document.getElementById(link.link);
  
        if (section) {
          const sectionTop = section.offsetTop;
          const sectionHeight = section.offsetHeight;
          const sectionCenter = sectionTop + sectionHeight / 2;
  
          if (sectionCenter > center - 100 && sectionCenter < center + 150) {
            setActiveLink(index);
          }
        }
      });
    };
  
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [links]);
    
  return (
    <>
      <Flex
        direction={'row'}
        justify={isMobile ? 'space-around' : 'space-between'}
        align="center"
        className={headerClass}
      >
        {isMobile ? (
          <Burger
            opened={openedMenu}
            onClick={() => setOpenedMenu(!openedMenu)}
            size="md"
            color="#1E6CBB"
            className={classes.burger}
          />
        ) : null}
        {isMobile ? (
          <Image
            src="/5.png"
            height={isMobile ? '3rem' : '4rem'}
            width="auto"
            style={{ cursor: 'pointer' }}
            alt='litrige-logo'
            onClick={() => {
              goToSection('introduction-section')
              setActiveLink(0)
            }}
          />
        ) : isLaptopS ? (
          <Image
            src="/submark-2.png"
            alt='litrige-logo-small'
            height={'3rem'}
            width={'auto'}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              goToSection('introduction-section')
              setActiveLink(0)
            }}
          />
        ) : (
          <Image
            src="/5.png"
            alt='litrige-logo'
            height={isMobile ? '3rem' : '4rem'}
            width="auto"
            onClick={() => {
              goToSection('introduction-section')
              setActiveLink(0)
            }}
            style={{ cursor: 'pointer' }}
          />
        )}

        {isMobile ? null : <Group spacing={0}>{items}</Group>}
        <LanguagePicker />
      </Flex>
      <Drawer
        opened={openedMenu}
        onClose={() => setOpenedMenu(false)}
        withCloseButton={false}
        position="top"
        size={'sm'}
        zIndex={25000}
        transitionProps={{ transition: 'fade', duration: 250, timingFunction: 'ease-in' }}
        classNames={{ body: classes.body, content: classes.content }}
      >
        <Stack align={'center'} spacing={0} mb="lg">
          <Image
            src={'/5.png'}
            alt='litrige-logo'
            height={isMobile ? '3rem' : '4rem'}
            width="auto"
            onClick={() => {
              goToSection('introduction-section')
              setActiveLink(0)
            }}
            style={{ cursor: 'pointer' }}
          />
          {itemsMobile}
        </Stack>
      </Drawer>
    </>
  )
}
