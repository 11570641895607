import {
  Paper,
  Stack,
  Image,
  TextInput,
  Textarea,
  Button,
  createStyles,
  Text,
  Notification,
} from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import emailjs from '@emailjs/browser'
import { maxHeightMobileSmall, maxWidthMobile, maxWidthTablet } from '../../constants'
import { useForm } from '@mantine/form'
import { IconCheck, IconX } from '@tabler/icons-react'

export const useStyles = createStyles((theme) => ({
  placeholder: {
    color: '#153f69',
    border: '1px solid #deebf4',
    fontFamily: 'Varela',
    '&::placeholder': { color: '#1c7ed6' },
  },
  containerForm: {
    backgroundColor: '#f5f9fc',
    [`@media (${maxWidthTablet})`]: {
      border: '1px solid #bce1f75e',
      margin: 'auto',
    },

    [`@media (${maxHeightMobileSmall})`]: {
      margin: '0em 0em 0em 0em',
    },
  },
  error: {
    fontSize: 'small',
    textAlign: 'left',
    marginLeft: 5,
  },
}))
export const FormGetInTouch = () => {
  const { classes } = useStyles()
  const isMobileSHeight = useMediaQuery(maxHeightMobileSmall)
  const isMobile = useMediaQuery(maxWidthMobile)
  const [visible, setVisible] = useState(false)
  const [messageEmail, setMessageEmail] = useState('')
  const { t } = useTranslation()

  const sendEmail = (e: any) => {
    e.preventDefault()

    if (formContact.validate().hasErrors === false) {
      setVisible(true)
      emailjs.sendForm('service_1vrar6l','template_4q2noef', e.target, 'VYWZion7rdCC8kdqA').then(
        function (response) {
          formContact.reset()
          setMessageEmail(t('succes_email_message'))
          setVisible(false)
        },
        function (error) {
          setMessageEmail(t('error_message_input'))
          setVisible(false)
        },
      )
    }
  }
  const formContact = useForm({
    initialValues: {
      name: '',
      email: '',
      message: '',
    },
    validate: {
      name: (value) => (value.length > 5 ? null : t('error_name_input')),
      email: (value) =>
        /^\S+@\S+$/.test(value) && value.length > 0 ? null : t('error_email_input'),
      message: (value) => (value.length > 10 ? null : t('error_message_input')),
    },
  })

  useEffect(() => {
    setTimeout(() => setMessageEmail(''), 1500)
  }, [messageEmail])

  useEffect(() => {
    formContact.clearErrors()
    formContact.reset()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18next.language])

  return (
    <Paper
      radius={'xl'}
      h={'fit-content'}
      w="100%"
      p={'0.5em'}
      py={'lg'}
      className={classes.containerForm}
    >
      <form onSubmit={(e) => sendEmail(e)}>
        <Stack
          align={'center'}
          justify="center"
          h="100%"
          w="100%"
          spacing={
            formContact.errors.name || formContact.errors.message || formContact.errors.email
              ? '0.5em'
              : '1em'
          }
        >
          {isMobileSHeight && isMobile ? (
            <Text size={25} ff={'Noto Sans'} fw={700} color="#1e6cbb">
              {t('contact_us')}
            </Text>
          ) : (
            <Image  alt='logo-litrige' src="/5.png" height={isMobile ? '2.5em' : '3.5em'} width="auto" />
          )}

          <TextInput
            name="name"
            w="100%"
            h="25%"
            maw={'80%'}
            radius={'md'}
            placeholder={t('name_field_form')}
            classNames={{ input: classes.placeholder, error: classes.error }}
            {...formContact.getInputProps('name')}
          />
          <TextInput
            name="email"
            w="100%"
            h="25%"
            maw={'80%'}
            radius={'md'}
            placeholder={t('email_field_form')}
            classNames={{ input: classes.placeholder, error: classes.error }}
            {...formContact.getInputProps('email')}
          />
          <Textarea
            name="message"
            w="100%"
            h="25%"
            maw={'80%'}
            radius={'md'}
            placeholder={t('message_field_form')}
            classNames={{ input: classes.placeholder, error: classes.error }}
            {...formContact.getInputProps('message')}
          />

          <Button
            radius="md"
            size={isMobile ? 'xs' : 'sm'}
            bg={'#1e6cbb'}
            fw="bold"
            type="submit"
            loading={visible}
          >
            {t('button_get_in_touch')}
          </Button>
          {messageEmail.includes('succes') || messageEmail.includes('successfully') ? (
            <Notification
              icon={<IconCheck size="1.1rem" />}
              color="teal"
              radius="md"
              ff={'Barlow'}
              bg="#ffffff"
              fz={'0.85em'}
              withCloseButton={false}
            >
              {messageEmail}
            </Notification>
          ) : messageEmail.includes('eroare') || messageEmail.includes('error') ? (
            <Notification
              icon={<IconX size="1.1rem" />}
              color="red"
              radius="md"
              ff={'Barlow'}
              bg="#ffffff"
              fz={'0.85em'}
              withCloseButton={false}
            >
              {messageEmail}
            </Notification>
          ) : null}
        </Stack>
      </form>
    </Paper>
  )
}
