export const dataClients = [
  { logoClient: '/amazon.png' },
  { logoClient: '/ring.png' },
  { logoClient: '/ness.png' },
  { logoClient: '/ibm.png' },
  { logoClient: '/geo.png' },
]

export const linksTablet = [
  { link: 'introduction-section', label: 'Home' },
  { link: 'how-we-can-help-section', label: 'How We Can Help?' },
  { link: 'card-who-we-are-section', label: 'Who We Are?' },
  { link: 'our-clients-section', label: 'Our Clients' },
  { link: 'get-in-touch-section', label: 'Get In Touch' },
  { link: 'contact-form-section', label: 'Contact Form' },
]
