export const maxWidthTablet = '(max-width: 62em)'
export const minWidthTablet = '(min-width: 40em)'
export const maxWidthLaptopSmall = '(max-width: 80em)'
export const maxWidthMobile = '(max-width: 39em)'
export const maxWidthMobileSmall = '(max-width: 23em)'
export const maxWidthMobileMedium = '(max-width: 26em)'
export const minWidthLaptop = '(min-width: 100em)'
export const maxHeightMobileSmall = '(max-height: 812px)'

export enum RoutesOptions {
  ROOT = '/',
  CAREER = '/despre-camelia-pau',
  SERVICES = '/domeniu-activitate',
}
export const theme = {
  fontSizes: {
    xs: '0.6em',
    sm: '0.75em',
    md: '1em',
    lg: '1.2em',
    xl: '1.5em',
  },

  colors: {
    primary: [
      '#e6f6ff',
      '#d4e7fb',
      '#a9cdf1',
      '#7cb1e9',
      '#559ae0',
      '#3e8bdc',
      '#2f84dc',
      '#1f71c4',
      '#1364b0',
      '#00569c',
    ],
  },
}
