import { createStyles, rem } from '@mantine/core'
export const ICON_SIZE = rem(90)

export const useStyles = createStyles((theme) => ({
  textCustomize: {
    fontFamily: 'Varela',
    color: '#1e6cbb',
    fontSize: 'medium',
    textAlign: 'justify',
  },
  content: {
    paddingTop: 0,
    paddingLeft: '1.5rem',
    paddingRight: '3rem',
  },
  itemHover: {
    '&:hover': {
      backgroundColor: 'white',
    },
  },

  item: {
    backgroundColor: 'transparent',
    borderBottom: '1px solid rgba(30, 108, 187, 0.1)',
    fontSize: theme.fontSizes.xl,
  },

  value: {
    color: '#79CEFF',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },

  chevron: { color: '#1e6cbb' },
  chevronActive: { color: '#79CEFF' },

  iconAccordion: {
    borderRadius: 10,
    backgroundColor: '#f5f9fc',
  },

  labelItemAccordion: {
    fontSize: 'large',
    fontWeight: 'bold',
    fontFamily: 'Nato Sans',
    color: '#1e6cbb',
  },
}))
