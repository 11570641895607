import { BackgroundImage, Paper, Stack, Text } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { maxWidthMobile } from '../../constants'

export default function CardWhoWeAre() {
  const isMobile = useMediaQuery(maxWidthMobile)

  const { t } = useTranslation()
  return (
    <BackgroundImage src="/background-card.png" radius={'xl'} h={isMobile ? '55%' : '100%'}>
      <Paper radius={'xl'} className={'card-background-blue'} h="100%" py={'2em'} px={'1em'}>
        <Stack
          w="100%"
          h="100%"
          p="lg"
          align="center"
          justify="center"
          styles={{ backgroundColor: 'rgba(2, 62, 159, 0.35)' }}
        >
          <Text align="center" color="#ffffff" size={'1.75em'} ff="Noto Sans" fw="bold">
            {t('who_we_are')}
          </Text>
          {isMobile ? (
            <Text align="justify" size={'md'} ff="Varela" color="#ffffffde" w={'100%'} weight="500">
              {t('content_who_we_are_mobile')}
            </Text>
          ) : (
            <Text align="center" size={'md'} ff="Varela" color="#ffffffde" w={'100%'} weight="500">
              {t('content_who_we_are_1')}
              <br />
              {t('content_who_we_are_2')}
              <br />
              {t('content_who_we_are_3')}
            </Text>
          )}
        </Stack>
      </Paper>
    </BackgroundImage>
  )
}
