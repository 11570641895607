import { Accordion, Grid, Image, Paper } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { Fade } from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next'
import { maxWidthMobile, maxWidthTablet } from '../../constants'
import { useStyles } from './how-we-can-help-classes'

export const AccordionBenefits = () => {
  const { classes } = useStyles()
  const isMobile = useMediaQuery(maxWidthMobile)
  const isTablet = useMediaQuery(maxWidthTablet)
  const { t } = useTranslation()

  return (
    <Accordion
      chevronPosition="right"
      chevronSize={50}
      transitionDuration={500}
      defaultValue="customization"
      classNames={{
        label: classes.labelItemAccordion,
        chevron: classes.chevron,
        control: classes.itemHover,
        item: classes.itemHover,
        content: classes.content,
      }}
    >
      <Grid style={{ flexDirection: isTablet ? 'column' : 'row' }}>
        <Grid.Col span={6} maw={'100%'} p={0}>
          <Fade damping={0.2} duration={500} cascade delay={0} fraction={1/5}>
            <Accordion.Item className={classes.item} value="requirements">
              <Accordion.Control
                px={isMobile ? 0 : 'md'}
                py={'xs'}
                icon={
                  <Paper p={'xs'} className={classes.iconAccordion}>
                    <Image alt="icon-task" src={'/task-list.png'} height={'2rem'} width={'2rem'} />
                  </Paper>
                }
              >
                {t('requirements_management')}
              </Accordion.Control>
              <Accordion.Panel className={classes.textCustomize}>
                {t('content_requirements_management')}
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item className={classes.item} value="variant">
              <Accordion.Control
                px={isMobile ? 0 : 'md'}
                py={'xs'}
                icon={
                  <Paper p={'xs'} className={classes.iconAccordion}>
                    <Image alt="icon-variety" src={'/variety.png'} height={'2rem'} width={'2rem'} />
                  </Paper>
                }
              >
                {t('variant_management')}
              </Accordion.Control>
              <Accordion.Panel className={classes.textCustomize}>
                {t('content_variant_management')}
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item className={classes.item} value="buid-release">
              <Accordion.Control
                px={isMobile ? 0 : 'md'}
                py={'xs'}
                icon={
                  <Paper p={'xs'} className={classes.iconAccordion}>
                    <Image alt="icon-build" src={'/build.png'} height={'2rem'} width={'2rem'} />
                  </Paper>
                }
              >
                {t('build_management')}
              </Accordion.Control>
              <Accordion.Panel className={classes.textCustomize}>
                {t('content_build_management')}
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item className={classes.item} value="audits-metrics">
              <Accordion.Control
                px={isMobile ? 0 : 'md'}
                py={'xs'}
                icon={
                  <Paper p={'xs'} className={classes.iconAccordion}>
                    <Image
                      alt="icon-dashboard"
                      src={'/dashboard.png'}
                      height={'2rem'}
                      width={'2rem'}
                    />
                  </Paper>
                }
              >
                {t('audits_metrics_reports')}
              </Accordion.Control>
              <Accordion.Panel className={classes.textCustomize}>
                {t('content_audits_metrics_reports')}
              </Accordion.Panel>
            </Accordion.Item>
          </Fade>
        </Grid.Col>
        <Grid.Col span={6} maw={'100%'} p={0}>
          <Fade damping={0.2} duration={500} cascade delay={isMobile ? 300 : 0} fraction={1/5}>
            <Accordion.Item className={classes.item} value="resource">
              <Accordion.Control
                px={isMobile ? 0 : 'md'}
                py={'xs'}
                icon={
                  <Paper p={'xs'} className={classes.iconAccordion}>
                    <Image
                      alt="icon-resource"
                      src={'/resource.png'}
                      height={'2rem'}
                      width={'2rem'}
                    />
                  </Paper>
                }
              >
                {t('resource_management')}
              </Accordion.Control>
              <Accordion.Panel className={classes.textCustomize}>
                {t('content_resource_management')}
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item className={classes.item} value="issue-defect">
              <Accordion.Control
                px={isMobile ? 0 : 'md'}
                py={'xs'}
                icon={
                  <Paper p={'xs'} className={classes.iconAccordion}>
                    <Image alt="icon-debug" src={'/debugging.png'} height={'2rem'} width={'2rem'} />
                  </Paper>
                }
              >
                {t('issue_defect_management')}
              </Accordion.Control>
              <Accordion.Panel className={classes.textCustomize}>
                {t('content_issue_defect_management')}
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item className={classes.item} value="change-configuration">
              <Accordion.Control
                px={isMobile ? 0 : 'md'}
                py={'xs'}
                icon={
                  <Paper p={'xs'} className={classes.iconAccordion}>
                    <Image
                      alt="icon-settings"
                      src={'/settings.png'}
                      height={'2rem'}
                      width={'2rem'}
                    />
                  </Paper>
                }
              >
                {t('change_configuration')}
              </Accordion.Control>
              <Accordion.Panel className={classes.textCustomize}>
                {t('content_change_configuration')}
              </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item className={classes.item} value="test-quality">
              <Accordion.Control
                px={isMobile ? 0 : 'md'}
                py={'xs'}
                icon={
                  <Paper p={'xs'} className={classes.iconAccordion}>
                    <Image
                      alt="icon-assurance"
                      src={'/assurance.png'}
                      height={'2rem'}
                      width={'2rem'}
                    />
                  </Paper>
                }
              >
                {t('test_quality_management')}
              </Accordion.Control>
              <Accordion.Panel className={classes.textCustomize}>
                {t('content_test_quality_management')}
              </Accordion.Panel>
            </Accordion.Item>
          </Fade>
        </Grid.Col>
      </Grid>
    </Accordion>
  )
}
