import { BackgroundImage, Flex, Paper, SimpleGrid, Stack, Text } from '@mantine/core'
import { useMediaQuery, useViewportSize } from '@mantine/hooks'
import { IconAt, IconMapPin, IconPhone, IconSun } from '@tabler/icons-react'
import React from 'react'
import { Fade } from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next'
import { MiddleContainer } from '../../assets/middle-container'
import { maxWidthMobile, maxWidthTablet, minWidthLaptop } from '../../constants'
import '../who-we-are-section/who-we-are-classes.scss'
import { FormGetInTouch } from './form-get-in-touch'
const icons = {
  at: IconAt,
  mapPin: IconMapPin,
  phone: IconPhone,
  sun: IconSun,
}

export const GetInTouchSection = () => {
  const isMobile = useMediaQuery(maxWidthMobile)
  const isTablet = useMediaQuery(maxWidthTablet)
  const isLaptopLg = useMediaQuery(minWidthLaptop)
  const { height, width } = useViewportSize()
  const { t } = useTranslation()

  const dataGetInTouch = [
    { icon: 'sun', nameField: t('working_hours'), valueField: '9 a.m - 6 p.m' },

    {
      icon: 'phone',
      nameField: t('phone_number'),
      valueField: '+40 765 187 138',
      link: 'tel:+40765-187-138',
    },
    {
      icon: 'at',
      nameField: t('email'),
      valueField: 'contact@litrige.com',
      link: 'mailto:contact@litrige.com',
    },
    {
      icon: 'mapPin',
      nameField: t('adress'),
      valueField: 'Martir Remus Tesala, Nr. 3, Timișoara, România',
      link: 'https://www.google.com/maps/place/Strada+Remus+T%C4%83sal%C4%83+3,+Timi%C8%99oara/@45.7605919,21.2116913,17z/data=!3m1!4b1!4m6!3m5!1s0x47456788fda52dd1:0x48ea0af825912033!8m2!3d45.7605882!4d21.2142662!16s%2Fg%2F11c24_qwtm?entry=ttu',
    },
  ]
  const fieldInfoContact = dataGetInTouch.map(
    (item: { icon: string; nameField: string; valueField: string; link?: string }, index) => {
      const Icon =
        item.icon === 'at'
          ? icons.at
          : item.icon === 'mapPin'
          ? icons.mapPin
          : item.icon === 'phone'
          ? icons.phone
          : icons.sun
      return (
        <Flex key={index} w="100%" align="center" justify={'flex-start'} pb={'xs'}>
          <Icon color="white" size="1.3em" />

          <Stack justify={'flex-start'} spacing={0} pl={'xs'}>
            {isMobile ? null : (
              <Text align="left" color={'white'} size={'xs'}>
                {item.nameField}
              </Text>
            )}
            {isMobile ? (
              <Text
                align="left"
                color={'white'}
                size={'md'}
                ff="Varela"
                component="a"
                target="_blank"
                href={item.link}
              >
                {item.valueField}
              </Text>
            ) : item.link?.includes('+40') ? (
              <Text align="left" color={'white'} size={'md'} ff="Varela">
                {item.valueField}
              </Text>
            ) : (
              <Text
                align="left"
                color={'white'}
                size={'md'}
                ff="Varela"
                component="a"
                target="_blank"
                href={item.link}
              >
                {item.valueField}
              </Text>
            )}
          </Stack>
        </Flex>
      )
    },
  )
  return (
    <MiddleContainer>
      <Stack
        justify={'start'}
        spacing={height < 800 || (height < 1000 && width < 1000) ? '3em' : '7em'}
        id="get-in-touch-section"
      >
        <Text align="center" color="#1e6cbb" size={'2em'} ff="Noto Sans" fw="bold">
          {t('we_convinced_you')}
        </Text>

        <BackgroundImage src="/background-card.png" radius={'xl'} h={'fit-content'}>
          <Paper radius={'xl'} className={'card-background-blue'} h="100%">
            <SimpleGrid
              cols={isMobile ? 1 : 2}
              p={'xl'}
              h="100%"
              mx={isTablet ? '0.5em' : '3em'}
              px={isLaptopLg ? '15em' : '1em'}
            >
              <Stack w={'100%'} h={'100%'} spacing={5}>
                <Fade duration={1200} cascade damping={0.1} fraction={1}>
                  <Text
                    align={isTablet ? 'center' : 'left'}
                    color="white"
                    size={'2em'}
                    ff="Noto Sans"
                    fw="bold"
                    w={isTablet ? '100%' : '80%'}
                  >
                    {t('get_in_touch')}
                  </Text>
                  <Text
                    align={isTablet ? 'center' : 'left'}
                    color="white"
                    size={'md'}
                    ff="Varela"
                    w={isTablet ? '100%' : '60%'}
                  >
                    {t('content_get_in_touch')}
                  </Text>
                  <Fade duration={500} delay={0} fraction={1 / 4}>
                    {fieldInfoContact}
                  </Fade>
                </Fade>
              </Stack>
              <Fade duration={500} delay={100} fraction={1 / 4}>
                <FormGetInTouch />
              </Fade>
            </SimpleGrid>
          </Paper>
        </BackgroundImage>
      </Stack>
    </MiddleContainer>
  )
}
