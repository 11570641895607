import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
  controls: {
    width: '100vw',
    left: '-2rem',
    right: '-2rem',
  },
  control: {
    color: '#1e6cbb',
    backgroundColor: '#f8f9fa',
    border: 'transparent',
  },
  indicators: { bottom: '-1rem' },
  indcator: {
    backgroundColor: '#1e6cbb',
  },
}))
