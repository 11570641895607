import { useEffect, useState } from 'react'
import { createStyles, UnstyledButton, Menu, Image, Group, rem } from '@mantine/core'
import { IconChevronDown } from '@tabler/icons-react'
import i18next from 'i18next'
import { useMediaQuery } from '@mantine/hooks'
import { maxWidthLaptopSmall, maxWidthMobile } from '../constants'

const data = [
  { value: 'en', label: 'English', image: '/uk.png' },
  { value: 'ro', label: 'Română', image: '/romania.png' },
  // { value: 'it', label: 'Italiano', image: '/italy.png' },
  // { value: 'fr', label: 'Français', image: '/france.png' },
  // { value: 'de', label: 'Deutsch', image: '/germany.png' },
]

const useStyles = createStyles((theme, { opened }: { opened: boolean }) => ({
  control: {
    maxWidth: rem(150),
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    width: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    borderRadius: theme.radius.md,
    transition: 'background-color 150ms ease',
    backgroundColor: opened ? '#f5fafe' : 'transparent',
    [`@media (${maxWidthMobile})`]: {
      padding: 0,
      '&:hover': {
        backgroundColor: '#f5fafe',
      },
    },
  },
  arrow: { color: '#79ceff' },
  label: {
    fontWeight: 700,
    fontFamily: 'Noto Sans',
    fontSize: theme.fontSizes.sm,
    color: '#1E6CBB',
  },

  icon: {
    transition: 'transform 150ms ease',
    transform: opened ? 'rotate(180deg)' : 'rotate(0deg)',
  },
  dropdown: {
    width: '3rem',
    border: '1px solid #e6f5fd',
  },
}))

export function LanguagePicker() {
  const [opened, setOpened] = useState(false)
  const { classes } = useStyles({ opened })
  const isLaptopS = useMediaQuery(maxWidthLaptopSmall)
  const [selected, setSelected] = useState(data[0])

  useEffect(() => {
    i18next.changeLanguage(selected.value)
  }, [selected.value])

  const items = data.map((item) => (
    <Menu.Item
      icon={<Image alt={item.label} src={item.image} width={18} height={18} />}
      onClick={() => {
        setSelected(item)
        if (item.label === 'Română') {
          i18next.changeLanguage('ro')
        } else i18next.changeLanguage('en')
      }}
      color="#1E6CBB"
      fw="500"
      key={item.label}
    >
      {item.label}
    </Menu.Item>
  ))

  return (
    <Menu
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      position={isLaptopS ? 'bottom-end' : 'bottom'}
      radius="md"
      width={'8em'}
      withinPortal
      zIndex={20000}
      classNames={{ dropdown: classes.dropdown, arrow: classes.arrow }}
    >
      <Menu.Target>
        <UnstyledButton className={classes.control}>
          {isLaptopS ? (
            <Image alt={selected.label} src={selected.image} width={22} height={22} />
          ) : (
            <Group spacing="xs">
              <Image alt={selected.label} src={selected.image} width={22} height={22} />
              <span className={classes.label}>{selected.label}</span>
            </Group>
          )}
          <IconChevronDown size="1rem" className={classes.icon} stroke={1.5} />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>{items}</Menu.Dropdown>
    </Menu>
  )
}
