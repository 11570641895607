import React from 'react'
import { BackgroundImage, MantineProvider, Stack } from '@mantine/core'
import { IntroductionSection } from './components/introduction-section/introduction-section'
import { HowWeCanHelpSection } from './components/how-we-can-help-section/how-we-can-help'
import { WhoWeAreSection } from './components/who-we-are-section/who-we-are-section'
import { OurClientsSection } from './components/clients-section/our-clients-section'
import { GetInTouchSection } from './components/get-in-touch-section/get-in-touch-section'
import { Header } from './components/header/header'

import './index.css'
import './App.scss'
import { Footer } from './components/footer/footer'
import { useMediaQuery } from '@mantine/hooks'
import { maxWidthMobile } from './constants'

function App() {
  const isMobile = useMediaQuery(maxWidthMobile)

  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{
        fontSizes: {
          xs: '0.75em',
          sm: '0.875em',
          md: '1em',
          lg: '1.125em',
          xl: '1.25em',
        },

        colors: {
          primary: [
            '#e6f6ff',
            '#d4e7fb',
            '#a9cdf1',
            '#7cb1e9',
            '#559ae0',
            '#3e8bdc',
            '#2f84dc',
            '#1f71c4',
            '#1364b0',
            '#00569c',
          ],
        },
      }}
    >
      <div className="App">
        <Header/>
        <BackgroundImage src="/background2.png">
          <div className="background-gadient">
            <IntroductionSection />
          </div>
        </BackgroundImage>
        <Stack spacing={isMobile ? '10rem' : '7rem'}>
          <HowWeCanHelpSection />
          <WhoWeAreSection />
          <OurClientsSection />
          <GetInTouchSection />
          <Footer />
        </Stack>
      </div>
    </MantineProvider>
  )
}

export default App
