import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDectector from 'i18next-browser-languagedetector'

i18next

  .use(initReactI18next)
  .use(LanguageDectector)
  .init({
    fallbackLng: 'en',
    resources: {
      en: {
        translation: {
          title_1: 'The Future Of ',
          title_2: 'Quality ',
          title_3: 'Assurance',
          motto:
            'Our mission is to become the models that we would like to follow and for that we Learn to Innovate, we Trust and Respect our partners, we have Integrity and we Grow with Energy.',
          button_start: 'Contact us',
          button_start_call: 'Call us',
          button_start_email: 'Send email',
          scroll_down: 'Scroll Down',
          home: 'Home',
          how_we_can_help: 'How We Can Help?',
          requirements_management: 'Requirements Management',
          content_requirements_management:
            'In quality assurance (QA), requirements management is like creating a detailed plan. It ensures that everyone involved understands what needs to be done and helps maintain focus as the project moves forward.',
          variant_management: 'Variant Management',
          content_variant_management:
            'Variant management in QA involves handling multiple versions of software to ensure consistent and reliable performance across different configurations and scenarios.',
          build_management: 'Built & Release Management',
          content_build_management: `In QA, "build and release" covers creating and delivering software builds to meet quality and user standards. It includes building, packaging, and coordinating deployments to ensure reliable and high-quality software products.`,
          audits_metrics_reports: 'Audits, Metrics, Reports',
          content_audits_metrics_reports:
            'In QA, reports provide project insights, metrics quantify performance, and audits ensure compliance and process improvement. Together, they enhance project quality and adherence to standards.',
          resource_management: 'Resource Management',
          content_resource_management:
            'Resource management in QA involves efficiently allocating people, tools, and budgets to ensure effective testing. It includes tasks like planning, balancing workloads, managing test environments, and optimizing resource utilization.',
          issue_defect_management: 'Issue & Defect Management',
          content_issue_defect_management: `Issue and defect management in QA is about identifying problems, tracking them, and resolving them efficiently. This involves reporting issues, prioritizing them, fixing defects, and ensuring that fixes don't introduce new problems.`,
          change_configuration: 'Change & Configuration',
          content_change_configuration:
            'Change and configuration management in QA handle software alterations systematically, maintaining consistency and quality. Change management approves and implements changes, while configuration management ensures software configurations remain consistent.',
          test_quality_management: 'Test & Quality Management',
          content_test_quality_management:
            'Test management in QA involves planning and executing tests to verify software meets requirements. Quality management focuses on defining, measuring, and improving overall product quality.',
          who_we_are: 'Our History',
          who_we_are_title: 'Who We Are?',
          content_who_we_are_1: `LITRIGE, established in the year 2022, stands as more than a conventional QA enterprise.`,
          content_who_we_are_2: `We proudly embody the spirit of disruption, constantly challenging the status-quo and passionately pioneering innovation.`,
          content_who_we_are_3:
            ' We are driven by a relentless enthusiasm for progress and a steadfast dedication to delivering the highest level of professionalism.',
          content_who_we_are_mobile:
            'LITRIGE, established in the year 2022, stands as more than a conventional QA enterprise. We proudly embody the spirit of disruption,constantly challenging the status-quo, and passionately pioneering innovation. Our unwavering commitment to redefining industry norms is woven into our DNA, as we tirelessly explore novel strategies to enhance our clients products and continuously refine our testing methodologies. We are driven by a relentless enthusiasm for progress and a steadfast dedication to delivering the highest level of professionalism.',

          industries: 'The Industries We Work In',
          metering_industry: 'Metering Industry',
          content_metering_industry:
            'Our projects are all about smart meters in the metering industry, covering both electricity and gas metering solutions.',
          web_industry: 'Web Industry',
          content_web_industry:
            'In the web industry, one of our notable projects was the EU-LISA Entry/Exit System, which showcased our expertise in this sector.',
          automotive_industry: 'Automotive Industry',
          content_automotive_industry:
            'In the automotive sector, we have significant projects related to instrument clusters for Volvo and PSA.',
          iot_industry: 'IoT Industry',
          content_iot_industry:
            'We have been actively involved in IoT industries, undertaking projects such as autonomous drone video surveillance and iOS/Android app development.',
          our_clients: 'Our Clients',
          we_convinced_you: 'Are You Convinced?',
          get_in_touch: 'Get In Touch',
          content_get_in_touch: 'Leave your email and we will get back to you within 24 hours.',
          email: 'Email',
          adress: 'Adress',
          phone_number: 'Phone Number',
          working_hours: 'Working Hours',
          name_field_form: 'Name',
          email_field_form: 'Email Adress',
          message_field_form: 'Message',
          button_get_in_touch: 'Send message',
          footer_massage:
            'Our mission is to become the models that we would like to follow and for that we Learn to Innovate, we Trust and Respect our partners, we have Integrity and we Grow with Energy',
          contact_us: 'Contact us',
          all_rights_reserved: 'All rights reserved',
          succes_email_message: 'The message was delivered successfully.',
          error_email_message: 'An error occurred, please try again.',
          error_name_input: 'The message must be at least 5 letters long.',
          error_email_input: 'The email address is incorrect.',
          error_message_input: 'The message must be at least 10 letters long.',
        },
      },
      ro: {
        translation: {
          title_1: ' LITRIGE,',
          title_2: 'excelență',
          title_3: 'în testare',
          motto:
            'Misiunea noastră este să devenim exemplele pe care ne-am dori să le urmăm și pentru asta învățăm să inovăm, avem încredere și respect față de partenerii noștri, suntem integri și creștem cu energie.',
          button_start: 'Contactează-ne',
          button_start_call: 'Sună acum',
          button_start_email: 'Scrie un email',
          scroll_down: 'Află mai multe',
          home: 'Acasă',

          how_we_can_help: 'Cum te putem ajuta?',
          requirements_management: 'Gestionarea necesităților',
          content_requirements_management:
            'În asigurarea calității (QA), gestionarea necesităților este similară cu crearea unui plan detaliat. Se asigură că toți cei implicați înțeleg ceea ce trebuie făcut și ajută la menținerea direcției pe măsură ce proiectul avansează.',
          variant_management: 'Gestionarea variantelor',
          content_variant_management:
            'Gestionarea variantelor în asigurarea calității (QA), implică gestionarea mai multor versiuni ale software-ului pentru a asigura o performanță constantă și fiabilă în diferite configurații și scenarii.',
          build_management: 'Gestionarea built & release ',
          content_build_management: `În domeniul (QA), "build and release" se referă la crearea și livrarea de software pentru a îndeplini standardele de calitate și de utilizare. Aceasta include crearea, pregătirea și coordonarea implementărilor pentru a asigura produse software fiabile și de înaltă calitate.`,
          audits_metrics_reports: 'Audituri, măsurători, rapoarte',
          content_audits_metrics_reports:
            'În domeniul asigurării calității, rapoartele oferă informații despre proiecte, măsurătorile cuantifică performanța, iar auditurile asigură conformitatea și îmbunătățirea proceselor. Împreună, acestea îmbunătățesc calitatea proiectului și respectarea standardelor.',
          resource_management: 'Gestionarea resurselor',
          content_resource_management:
            'Gestionarea resurselor în domeniul (QA), implică repartizarea eficientă a persoanelor, instrumentelor și bugetelor pentru a asigura o testare eficientă. Acesta include sarcini precum planificarea, distribuirea echilibrată a sarcinilor de lucru, gestionarea mediilor de testare și optimizarea utilizării resurselor.',
          issue_defect_management: 'Gestionarea problemelor și defectelor',
          content_issue_defect_management: `Gestionarea problemelor și a defectelor în cadrul (QA), constă în identificarea problemelor, localizarea lor și rezolvarea lor în mod eficient. Acest lucru implică raportarea problemelor, stabilirea priorităților acestora, remedierea defectelor și asigurarea faptului că soluțiile nu creează noi probleme.`,
          change_configuration: 'Modificare și configurare',
          content_change_configuration:
            'Gestionarea modificărilor și a configurației în domeniul (QA), se ocupă în mod sistematic de modificările software, menținând consecvența și calitatea. Gestionarea modificărilor aprobă și pune în aplicare schimbările, în timp ce gestionarea configurației asigură menținerea coerenței configurațiilor software.',
          test_quality_management: 'Testare și administrarea calității',
          content_test_quality_management:
            'Gestionarea testelor în domeniul (QA), implică planificarea și executarea testelor pentru a verifica dacă software-ul îndeplinește cerințele. Administrarea calității se concentrează pe definirea, măsurarea și îmbunătățirea calității globale a produsului.',

          who_we_are: 'Despre noi',
          who_we_are_title: 'Cine suntem?',
          content_who_we_are_1:
            'LITRIGE, înființată în anul 2022, este mai mult decât o întreprindere convențională de asigurare a calității.',
          content_who_we_are_2: `Noi îmbrățișăm cu mândrie spiritul de transformare, provocam in mod constant status-quo-ul și inovăm cu pasiune.`,
          content_who_we_are_3:
            'Suntem motivați de un entuziasm neobosit pentru progres și de o dedicare fermă pentru a oferi cel mai înalt nivel de profesionalism.',
          content_who_we_are_mobile:
            'LITRIGE, fondată în 2022, este mai mult decât o companie obișnuită de asigurare a calității. Noi îmbrățișăm cu mândrie spiritul de transformare, provocam in mod constant status-quo-ul și inovăm cu pasiune. Suntem motivați de un entuziasm neobosit pentru progres și de o dedicare fermă pentru a oferi cel mai înalt nivel de profesionalism.',
          industries: 'Industriile în care activăm',
          metering_industry: 'Industria de Măsurători',
          content_metering_industry:
            'Proiectele noastre se referă la contoare inteligente în industria măsurătorilor, acoperind atât soluții de măsurare a energiei electrice, cât și a gazelor naturale.',
          web_industry: 'Industria Web',
          content_web_industry:
            'În industria web, unul dintre proiectele noastre importante a fost sistemul de intrare/ieșire EU-LISA, care a pus în evidență competența noastră în acest sector.',
          automotive_industry: 'Industria Automotive',
          content_automotive_industry:
            'În sectorul automotive, avem proiecte importante legate de clustere de instrumente pentru Volvo și PSA.',
          iot_industry: 'Industria IoT',
          content_iot_industry:
            'Ne-am implicat în mod activ în industria IoT, realizând proiecte precum supravegherea video autonomă cu drone și dezvoltarea de aplicații iOS/Android.',
          our_clients: 'Clienții noștri',
          get_in_touch: 'Contact',
          we_convinced_you: 'Te-am convins?',
          content_get_in_touch:
            'Lasă-ne adresa ta de e-mail și îți vom răspunde în termen de 24 de ore.',
          email: 'Email',
          adress: 'Adresa',
          phone_number: 'Număr de telefon',
          working_hours: 'Programul de lucru',
          name_field_form: 'Nume',
          email_field_form: 'Adresa de email',
          message_field_form: 'Mesajul tău',
          button_get_in_touch: 'Trimite mesajul',
          footer_massage:
            'Misiunea noastră este să devenim exemplele pe care ne-am dori să le urmăm și pentru asta învățăm să inovăm, avem încredere și respect față de partenerii noștri, suntem integri și creștem cu energie.',
          contact_us: 'Contactează-ne',
          all_rights_reserved: 'Toate drepturile sunt rezervate.',
          succes_email_message: 'Mesajul a fost transmis cu succes.',
          error_email_message: 'A apărut o eroare, vă rugăm să încercați din nou.',
          error_name_input: 'Mesajul trebuie să conțină cel puțin 5 litere.',
          error_email_input: 'Adresa de email nu este corectă.',
          error_message_input: 'Mesajul trebuie să conțină cel puțin 10 litere.',
        },
      },
    },
  })
