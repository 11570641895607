import { Text, Container, ActionIcon, Group, Image, Flex } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { Fade } from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next'
import { maxWidthMobile } from '../../constants'
import { useStyles } from './footer-classes'

export function Footer() {
  const isMobile = useMediaQuery(maxWidthMobile)
  const { classes } = useStyles()
  const { t } = useTranslation()
  const goToSection = (section: string) => {
    const element = document.getElementById(section)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
    }
  }

  return (
    <Fade duration={500}>
      <footer className={classes.footer}>
        <Flex
          direction={isMobile ? 'column-reverse' : 'row'}
          align={'center'}
          justify={isMobile ? 'center' : 'space-between'}
        >
          <Flex gap={5} direction={'row'} align={'center'} justify={isMobile ? 'center' : 'start'}>
            <Image
            alt='logo-litrige'
              src="/submark.png"
              height={'2.5rem'}
              width={'auto'}
              onClick={() => {
                goToSection('introduction-section')
              }}
              style={{ cursor: 'pointer' }}
            />
            <Text size="xs" color="white" className={classes.description}>
              {t('footer_massage')}
            </Text>
          </Flex>

          <Group spacing={0} className={classes.social} position="left" noWrap>
            <ActionIcon
              variant="transparent"
              size="lg"
              component="a"
              href={'https://www.instagram.com/litrige.ro/'}
              target="_blank"
              aria-label='Instagram Link'
            >
              <Image alt='logo-instagram' src="/instagram.png" height={20} width={20} />
            </ActionIcon>
            <ActionIcon
              variant="transparent"
              size="lg"
              component="a"
              href={'https://ro.linkedin.com/company/litrige?trk=public_post_feed-actor-image'}
              target="_blank"
              aria-label='LinkedIn Link'
            >
              <Image alt='logo-linkedin' src="/linkedin.png" height={20} width={20} />
            </ActionIcon>
            <ActionIcon
              variant="transparent"
              size="lg"
              component="a"
              href={'https://www.facebook.com/LITRIGE'}
              target="_blank"
              aria-label='Facebook Link'
            >
              <Image alt='logo-facebook' src="/facebook.png" height={20} width={20} />
            </ActionIcon>
          </Group>
        </Flex>

        <Container className={classes.afterFooter}>
          <Text color="#ffffff73" size="xs">
            © 2023 Litrige. {t('all_rights_reserved')}
          </Text>
          <Text color="#ffffff73" size="xs">
            Terms & Policy
          </Text>
        </Container>
      </footer>
    </Fade>
  )
}
