import { createStyles } from '@mantine/core'
import { maxWidthMobile, maxWidthTablet } from '../../constants'

export const useStyles = createStyles((theme) => ({
  headerStatic: {
    height: 60,
    zIndex: 1500,
    background: 'transparent',
    position: 'fixed',
    top: 0,
    width: '100%',
    [theme.fn.largerThan('sm')]: {
      paddingLeft: '2em',
      paddingRight: '2em',
    },
    [theme.fn.largerThan('md')]: {
      paddingLeft: '3.5em',
      paddingRight: '3.5em',
    },
    [theme.fn.largerThan('xl')]: {
      paddingLeft: '5em',
      paddingRight: '5em',
    },
    [theme.fn.largerThan(1800)]: {
      paddingLeft: '10em',
      paddingRight: '10em',
    },
    [theme.fn.largerThan(2000)]: {
      paddingLeft: '15em',
      paddingRight: '15em',
    },
    [theme.fn.largerThan(2300)]: {
      paddingLeft: '25em',
      paddingRight: '25em',
    },
  },
  headerScrolled: {
    height: 60,
    zIndex: 1500,
    background: 'white',
    position: 'fixed',
    top: 0,
    width: '100%',
    [theme.fn.largerThan('sm')]: {
      paddingLeft: '2em',
      paddingRight: '2em',
    },
    [theme.fn.largerThan('md')]: {
      paddingLeft: '3.5em',
      paddingRight: '3.5em',
    },
    [theme.fn.largerThan('xl')]: {
      paddingLeft: '5em',
      paddingRight: '5em',
    },
    [theme.fn.largerThan(1800)]: {
      paddingLeft: '10em',
      paddingRight: '10em',
    },
    [theme.fn.largerThan(2000)]: {
      paddingLeft: '15em',
      paddingRight: '15em',
    },
    [theme.fn.largerThan(2300)]: {
      paddingLeft: '25em',
      paddingRight: '25em',
    },
  },
  link: {
    color: '#1e6cbb',
    fontFamily: 'Noto Sans',
    fontSize: '0.85em',
    fontWeight: 700,
    textAlign: 'left',

    [`@media ( ${maxWidthTablet})`]: {
      fontSize: '0.75em',
    },
    [`@media ( ${maxWidthMobile})`]: {
      fontSize: '0.85em',
    },
  },
  linkActive: {
    textAlign: 'left',
    color: '#1E6CBB',
    fontFamily: 'Noto Sans',
    fontSize: '0.85em',
    fontWeight: 700,
    background: '#7fd6fbad',

    [`@media ( ${maxWidthTablet})`]: {
      fontSize: '0.75em',
    },
    [`@media ( ${maxWidthMobile})`]: {
      fontSize: '0.85em',
    },
  },
  dropdown: {
    width: '3rem',
    border: '1px solid #e6f5fd',
  },
  arrow: { color: '#79ceff' },
  titleMenu: {
    width: '100%',
    color: '#1E6CBB',
    fontFamily: 'Noto Sans',
    fontWeight: 600,
    display: 'flex',
    fontSize: 20,
    justifyContent: 'center',
  },
  burger: { zIndex: 30000 },
  body: { padding: 0, paddingTop: '1rem' },
  content: { height: 'fit-content' },
}))
