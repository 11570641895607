import { Button, Group, Menu, rem, Stack, Text } from '@mantine/core'
import React from 'react'
import { useMediaQuery } from '@mantine/hooks'
import { maxWidthMobile, maxWidthMobileSmall, theme } from '../../constants'
import '../who-we-are-section/who-we-are-classes.scss'
import { MiddleContainer } from '../../assets/middle-container'
import { useTranslation, Trans } from 'react-i18next'
import { Fade } from 'react-awesome-reveal'
import { IconAt, IconChevronDown, IconPhoneCall } from '@tabler/icons-react'
export const IntroductionSection = () => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery(maxWidthMobile)
  const isMobileS = useMediaQuery(maxWidthMobileSmall)

  return (
    <MiddleContainer>
      <Stack h={'100dvh'} id="introduction-section">
        <Stack
          h={'100%'}
          justify={'center'}
          align={isMobile ? 'center' : 'flex-start'}
          spacing={'2em'}
        >
          <Fade duration={1000} cascade damping={0.2}>
            <Stack spacing={isMobileS ? '1em' : isMobile ? '2em' : '1em'}>
              <Group
                h={'4rem'}
                position={isMobile ? 'center' : 'left'}
                spacing={isMobile ? 0 : '1rem'}
              >
                <Trans i18nKey={'title'}>
                  <Text
                    color="#1E6CBB"
                    size={isMobileS ? '2.2em' : '2.6em'}
                    align={isMobile ? 'center' : 'left'}
                    style={{ lineHeight: 0.7 }}
                    ff="Noto Sans"
                    fw="bold"
                  >
                    {t('title_1')}
                  </Text>
                  <Text
                    color="#79CEFF"
                    size={isMobileS ? '2.2em' : '2.6em'}
                    ff="Noto Sans"
                    fw="bold"
                    align={isMobile ? 'center' : 'left'}
                  >
                    {t('title_2')}
                  </Text>
                </Trans>
              </Group>
              <Text
                color="#1E6CBB"
                size={isMobileS ? '2.2em' : '2.6em'}
                align={isMobile ? 'center' : 'left'}
                style={{ lineHeight: 0.5 }}
                ff="Noto Sans"
                fw="bold"
              >
                {t('title_3')}
              </Text>
            </Stack>

            <Text
              align={isMobile ? 'center' : 'left'}
              color={'#1e6cbb'}
              ff={'Varela'}
              size={'lg'}
              w={isMobile ? '100%' : '50%'}
              classNames
            >
              {t('motto')}
            </Text>
          </Fade>
          {isMobile ? (
            <Menu
              transitionProps={{ transition: 'pop-top-right' }}
              position="bottom-start"
              width={'fit-content'}
              withinPortal
            >
              <Menu.Target>
                <Button
                  radius={'md'}
                  size={isMobile ? 'xs' : 'sm'}
                  w="fit-content"
                  bg={theme.colors.primary[8]}
                  rightIcon={
                    <IconChevronDown style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
                  }
                  pr={12}
                >
                  {t('button_start')}
                </Button>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  icon={<IconPhoneCall style={{ width: rem(16), height: rem(16) }} stroke={2} />}
                  color="#1e6cbb"
                  fw="500"
                  component="a"
                  href="tel:+40765-187-138"
                >
                  {t('button_start_call')}
                </Menu.Item>
                <Menu.Item
                  icon={<IconAt style={{ width: rem(16), height: rem(16) }} stroke={2} />}
                  color="#1e6cbb"
                  fw="500"
                  component="a"
                  href="mailto:contact@litrige.com"
                >
                  {t('button_start_email')}
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          ) : (
            <Button
              radius={'md'}
              size={isMobile ? 'xs' : 'sm'}
              w="fit-content"
              bg={theme.colors.primary[8]}
              component="a"
              href="mailto:contact@litrige.com"
            >
              {t('button_start')}
            </Button>
          )}
        </Stack>
      </Stack>
    </MiddleContainer>
  )
}
