import { Stack, Text } from '@mantine/core'
import React, { FunctionComponent } from 'react'
import { AccordionBenefits } from './utils-how-we-can-help'
import { useViewportSize } from '@mantine/hooks'
import { useTranslation } from 'react-i18next'
import { MiddleContainer } from '../../assets/middle-container'

export const HowWeCanHelpSection: FunctionComponent = () => {
  const { height, width } = useViewportSize()
  const { t } = useTranslation()

  return (
    <MiddleContainer>
      <Stack
        justify={'start'}
        spacing={height < 800 || (height < 1000 && width < 1000) ? '3em' : '7em'}
        id="how-we-can-help-section"
        style={{
          scrollMargin: '5rem'
        }}
      >
        <Text align="center" color="#1e6cbb" size={'2em'} ff="Noto Sans" fw="bold">
          {t('how_we_can_help')}
        </Text>

        <AccordionBenefits />
      </Stack>
    </MiddleContainer>
  )
}
