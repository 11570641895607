import { Avatar, BackgroundImage, Paper, Stack, Text, Image } from '@mantine/core'
import React, { FunctionComponent } from 'react'
import '../who-we-are-section/who-we-are-classes.scss'
import { useMediaQuery } from '@mantine/hooks'
import { maxWidthTablet } from '../../constants'
import { useStyles } from './who-we-are-classes'

export const CardIndusty: FunctionComponent<{ title: string; icon: string; content: string }> = ({
  title,
  icon,
  content,
}) => {
  const isTablet = useMediaQuery(maxWidthTablet)
  const { classes } = useStyles()
  return (
    <BackgroundImage src="background-card.png" radius={'xl'} h={'100%'}>
      <Paper radius={'xl'} p="xl" className={'card'} h="100%">
        <Stack spacing={'1rem'} align={isTablet ? 'center' : 'flex-start'}>
          <Avatar size="lg" radius="xl" classNames={{ placeholder: classes.placeholder }}>
            <Image alt='icon-1' src={icon} height="2rem" width="2rem" />
          </Avatar>

          <Stack spacing={'xs'}>
            <Text className={'title'} align={isTablet ? 'center' : 'left'}>
              {title}
            </Text>
            <Text mb="md" align="justify" className={'content'} size={'md'}>
              {content}
            </Text>
          </Stack>
        </Stack>
      </Paper>
    </BackgroundImage>
  )
}
